import React, { useEffect, useState } from "react"
import { Link, useIntl, navigate } from "gatsby-plugin-intl"
import { useForm } from "react-hook-form"

import Layout from "../components/layout"
import SEO from "../components/seo"

// Components
import SocialMedia from "../components/social-media"

// Hooks
import useTranslation from "../hooks/useTranslation"

// Icons
import CallIcon from "../assets/contact_call.inline.svg"
import MailIcon from "../assets/contact_mail.inline.svg"
import BuildingIcon from "../assets/contact_building.inline.svg"
import ArrowRightIcon from "../assets/icons/arrow.svg"

// Utils
import getCookieValue from "../utils/getCookieValue"

// Images
import TwistedShapesLeft from "../assets/hero-twisited-shapes-2-left.svg"
import TwistedShapesRight from "../assets/hero-twisited-shapes-2-right.svg"

// Style
import "../styles/pages/contact.scss"

const FormSection = () => {
  const { register, handleSubmit, errors } = useForm()
  const [hubspotToken, setHubspotToken] = useState("")
  useEffect(() => {
    setHubspotToken(getCookieValue("hubspotutk"))
  }, [])
  const { t } = useTranslation()
  const intl = useIntl()
  const encode = data => {
    return Object.keys(data)
      .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
      .join("&")
  }
  const onSubmit = (data, e) => {
    e.preventDefault()
    data.Mobile = data["Mobile Code"] + data.Mobile
    delete data["Mobile Code"]
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": "Contact",
        language: intl.locale,
        "Hubspot User Toker": hubspotToken,
        ...data,
      }),
    })
      .then(() => navigate("/form-success"))
      .catch(error => alert(error))
  }
  return (
    <>
      <h1 className="has-text-centered-tablet title is-2 has-text-primary main-title">
        {t("contact_main_title")}
      </h1>

      <div className="is-card">
        <h2 className="card-title  is-size-3 is-size-5-mobile">
          {t("contact_form_title")}
        </h2>

        <form
          name="Contact"
          method="POST"
          data-netlify="true"
          action="/form-success"
          onSubmit={handleSubmit(onSubmit)}
        >
          <input
            type="hidden"
            name="language"
            value={intl.locale}
            aria-label="language"
          />
          <input
            type="hidden"
            name="Hubspot User Toker"
            value={hubspotToken}
            aria-label="Hubspot User Toker"
          />
          <div className="columns">
            <div className="column">
              <div className="field">
                <label
                  className="label is-size-7 is-inline "
                  htmlFor="firstname"
                >
                  {t("First name")}
                </label>
                <span className="is-size-7 has-text-danger">*</span>
                <div className="control has-margin-top-3">
                  <input
                    ref={register({
                      required: t("This field is required"),
                      pattern: {
                        value: /^[A-Za-z]+$/i,
                        message: t("This field is invalid"),
                      },
                    })}
                    className={`input ${errors.Firstname && "is-danger"}`}
                    type="text"
                    name="Firstname"
                    id="firstname"
                    aria-label={t("First name")}
                  />
                </div>
                {errors.Firstname && errors.Firstname.message && (
                  <p className="help is-danger">{errors.Firstname.message}</p>
                )}
              </div>
            </div>
            <div className="column">
              <div className="field">
                <label className="label is-size-7 is-inline" htmlFor="lastname">
                  {t("Last name")}
                </label>
                <span className="is-size-7 has-text-danger">*</span>
                <div className="control has-margin-top-3">
                  <input
                    ref={register({
                      required: t("This field is required"),
                      pattern: {
                        value: /^[A-Za-z]+$/i,
                        message: t("This field is invalid"),
                      },
                    })}
                    className={`input ${errors.Lastname && "is-danger"}`}
                    type="text"
                    name="Lastname"
                    id="lastname"
                    aria-label={t("Last name")}
                  />
                </div>
                {errors.Lastname && errors.Lastname.message && (
                  <p className="help is-danger">{errors.Lastname.message}</p>
                )}
              </div>
            </div>
          </div>
          <div className="columns">
            <div className="column">
              <div className="field">
                <label className="label is-size-7 is-inline" htmlFor="email">
                  {t("Email address")}
                </label>
                <span className="is-size-7 has-text-danger">*</span>
                <div className="control has-margin-top-3">
                  <input
                    ref={register({
                      required: t("This field is required"),
                      pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                        message: t("This field is invalid"),
                      },
                    })}
                    className={`input ${errors.Email && "is-danger"}`}
                    type="email"
                    name="Email"
                    id="email"
                    aria-label={t("Email address")}
                  />
                </div>

                {errors.Email && errors.Email.message && (
                  <p className="help is-danger">{errors.Email.message}</p>
                )}
              </div>
            </div>
            <div className="column">
              <div className="field">
                <label className="label is-size-7 is-inline" htmlFor="website">
                  {t("Website")} ({t("optional")})
                </label>
                <div className="control has-margin-top-3">
                  <input
                    ref={register({
                      pattern: {
                        value: /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/gm,
                        message: t("This field is invalid"),
                      },
                    })}
                    className={`input ${errors.Website && "is-danger"}`}
                    type="text"
                    name="Website"
                    id="website"
                    aria-label={t("Website")}
                  />
                </div>
                {errors.Website && errors.Website.message && (
                  <p className="help is-danger">{errors.Website.message}</p>
                )}
              </div>
            </div>
          </div>

          <div className="columns">
            <div className="column">
              <div className="field ">
                <label className="label is-size-7 is-inline" htmlFor="mobile">
                  {t("Mobile number")} ({t("optional")})
                </label>
                <div className="field has-addons has-margin-top-3 field-group-wrapper">
                  <p style={{ width: "70px" }} className="control">
                    <input
                      ref={register({
                        pattern: {
                          value: /^(\+||00)([1-9]+)/,
                          message: t("This field is invalid"),
                        },
                      })}
                      className={`input ${errors["Mobile Code"] &&
                        "is-danger"}`}
                      type="text"
                      aria-label={t("Mobile number")}
                      name="Mobile Code"
                      id="mobileCode"
                      placeholder="+00"
                    />
                  </p>
                  <p className="control" style={{ width: "calc(100% - 70px)" }}>
                    <input
                      className={`input ${errors.Mobile && "is-danger"}`}
                      ref={register({
                        pattern: {
                          value: /[1-9]+/,
                          message: t("This field is invalid"),
                        },
                      })}
                      type="text"
                      name="Mobile"
                      id="mobile"
                      aria-label={t("Mobile number")}
                    />
                  </p>
                </div>
              </div>
            </div>
            <div className="column">
              <div className="field">
                <label className="label is-size-7 is-inline" htmlFor="role">
                  {t("Role")} ({t("optional")})
                </label>
                <div className="control has-margin-top-3">
                  <input
                    ref={register({
                      pattern: {
                        value: /[a-zA-Z]+/,
                        message: t("This field is invalid"),
                      },
                    })}
                    className={`input ${errors.Role && "is-danger"}`}
                    type="text"
                    name="Role"
                    id="role"
                    aria-label={t("Role")}
                  />
                </div>
                {errors.Role && errors.Role.message && (
                  <p className="help is-danger">{errors.Role.message}</p>
                )}
              </div>
            </div>
          </div>

          <div className="field has-margin-bottom-4">
            <label className="label is-size-7 is-inline" htmlFor="message">
              {t("Your message")}
            </label>
            <span className="is-size-7 has-text-danger">*</span>
            <div className="control has-margin-top-3">
              <textarea
                ref={register({
                  required: t("This field is required"),
                })}
                className={`textarea ${errors.Message ? "is-danger" : ""}`}
                name="Message"
                id="message"
                aria-label={t("Your message")}
              />
            </div>
            {errors.Message && errors.Message.message && (
              <p className="help is-danger">{errors.Message.message}</p>
            )}
          </div>
          <div className="has-margin-bottom-4 legal-info">
            <p className=" has-margin-bottom-3">
              {intl.formatMessage(
                {
                  id: "contact_legal1-text",
                },
                {
                  link: (
                    <Link
                      className="has-text-primary"
                      to="/terms-and-conditions"
                    >
                      {t("contact_legal1-link")}
                    </Link>
                  ),
                }
              )}
            </p>
          </div>
          <div className="has-margin-bottom-4 legal-info">
            <p className=" has-margin-bottom-3">{t("contact_legal2-text")}</p>
            <label className="checkbox has-margin-right-4 is-inline-flex">
              <input
                ref={register()}
                type="checkbox"
                className="has-margin-right-3"
                name="Allow Email"
                aria-label={t("contact_legal2-checkbox1")}
              />
              {t("contact_legal2-checkbox1")}
            </label>
            <label className="checkbox has-margin-right-2 is-inline-flex">
              <input
                ref={register()}
                type="checkbox"
                className="has-margin-right-3"
                name="Allow SMS"
                aria-label={t("contact_legal2-checkbox2")}
              />
              {t("contact_legal2-checkbox2")}
            </label>
          </div>
          <div className="has-text-centered">
            <button className="button is-rounded is-deep-dark-blue">
              {t("contact_CTA")}
            </button>
          </div>
        </form>
      </div>
    </>
  )
}

const InfoSection = () => {
  const { t } = useTranslation()
  const items = [
    {
      icon: <CallIcon />,
      title: t("contact_item1_title"),
      subtitle: `${t("contact_item1_sub")} (+40) 791 180 629`,
    },
    {
      icon: <BuildingIcon />,
      title: t("contact_item3_title"),
      subtitle: `sales@twispay.com
      marketing@twispay.com`,
    },
    {
      icon: <MailIcon />,
      title: t("contact_item2_title"),
      subtitle: `48 Iancu de Hunedoara,
      Crystal Tower, Sector 1,
      011745 Bucharest Romania`,
    },
  ]
  return (
    <div>
      {items.map(item => (
        <div key={item.title} className="columns item-wrapper is-mobile">
          <div className="column is-3-tablet is-2-fullhd">
            <span className="icon">{item.icon}</span>
          </div>
          <div className="column">
            <h3 className="title has-text-white">{item.title}</h3>
            <p className="subtitle is-6 is-pre-line has-text-white">
              {item.subtitle}
            </p>
          </div>
        </div>
      ))}
      <div className="columns">
        <div className="column is-offset-3-tablet is-offset-2-fullhd has-text-centered-mobile">
          <h3 className="title is-6  is-spaced has-margin-bottom-3 faq-title">
            {t("contact_right_section2_item1-title")}
          </h3>
          <Link to="/faq" className="has-text-deep-dark-blue is-size-3 ">
            {t("contact_right_section2_item1-link")}{" "}
            <span style={{ width: 13, height: 13 }} className="icon ">
              <ArrowRightIcon />
            </span>
          </Link>
          <h3 style={{ marginTop: 60 }} className="title is-6 ">
            {t("contact_right_section2_item2-title")}
          </h3>
          <SocialMedia
            className="has-text-deep-dark-blue social-media has-text-centered is-hcentered-mobile"
            size={29}
          />
          {/* <p
            style={{ fontSize: 12, maxWidth: 350 }}
            className="is-hidden-mobile"
          >
            {t("contact_right_section2_item3-title")}
          </p>
          <div className="is-flex is-hspaced is-vcentered is-hidden-mobile">
            <span style={{ width: 111, height: 111 }} className="icon pci-logo">
              <img src={PCILogo} alt="PCI" />
            </span>
            <span style={{ width: 86, height: 86 }} className="icon visa-logo">
              <img src={VisaLogo} alt="Visa" />
            </span>
            <span
              style={{ width: 86, height: 86 }}
              className="icon mastercard-logo"
            >
              <img
                className="has-margin-left-3 has-margin-right-3"
                src={MastercardLogoFull}
                alt="Mastercard"
              />
            </span>
          </div> */}
        </div>
      </div>
    </div>
  )
}

const ContactPage = () => {
  return (
    <Layout
      stripeColor="#2d264f"
      pageName="contact"
      hasFooterSimplified
      TwistedShapesLeft={TwistedShapesLeft}
      TwistedShapesRight={TwistedShapesRight}
    >
      <SEO title="Contact Twispay" />
      <section className="section is-hero">
        <div className="container">
          <div className="columns is-centered">
            <div className="column ">
              <div className="columns" >
                <div className="column is-offset-1-fullhd is-6-fullhd form-section">
                  <FormSection />
                </div>
                <div className="column is-4 is-offset-1 info-section" >
                  <InfoSection />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default ContactPage
